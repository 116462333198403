import React from 'react'
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import h1 from '../assets/1.png'
import h2 from '../assets/2.png'
import h3 from '../assets/3.png'
import h4 from '../assets/4.png'
import h5 from '../assets/5.png'
import h6 from '../assets/6.png'



const Hero = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    <div className="relative overflow-hidden bg-white py-10">
      <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
        <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
          <div className="sm:max-w-lg">
         
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Bringing New Era of Education
            </h1>
            <p className="mt-4 text-xl text-gray-500">
            Empowering the Next Generation of Innovators: Hands-on Workshops to Ignite the Passion for Technology in Budding Tech Enthusiasts
            </p>
          </div>
          <div>
            <div className="mt-10">
              {/* Decorative image grid */}
              <div
                aria-hidden="true"
                className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
              >
                <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8  lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                  <div className="flex items-center space-x-6 lg:space-x-8">
                    <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                      <div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                        <img
                          src={h1}
                          alt=""
                          className="h-full w-full object-cover object-center"
                        />
                      </div>
                      <div className="h-64 w-44 overflow-hidden rounded-lg">
                        <img
                          src={h3}
                          alt=""
                          className="h-full w-full object-cover object-center"
                        />
                      </div>
                    </div>
                    <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                      <div className="h-64 w-44 overflow-hidden rounded-lg">
                        <img
                          src={h2}
                          alt=""
                          className="h-full w-full object-cover object-center"
                        />
                      </div>
                      <div className="h-64 w-44 overflow-hidden rounded-lg">
                        <img
                          src={h4}
                          alt=""
                          className="h-full w-full object-cover object-center"
                        />
                      </div>
                      <div className="h-64 w-44 overflow-hidden rounded-lg">
                        <img
                          src={h5}
                          alt=""
                          className="h-full w-full object-cover object-center"
                        />
                      </div>
                    </div>
                    <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                      <div className="h-64 w-44 overflow-hidden rounded-lg">
                        <img
                          src={h6}
                          alt=""
                          className="h-full w-full object-cover object-center"
                        />
                      </div>
                      <div className="h-64 w-44 overflow-hidden rounded-lg">
                        <img
                          src={h4}
                          alt=""
                          className="h-full w-full object-cover object-center"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <a
                href="/workshop"
                className="inline-block sm: rounded-md border border-transparent bg-red-700 px-8 py-3 text-center font-medium text-white hover:bg-slate-800"
              >
                Explore Workshops
              </a>
              <a
                href="/labsetup"
                className="inline-block rounded-md border ml-2 border-transparent bg-red-700 px-8 py-3 text-center font-medium text-white hover:bg-slate-800"
              >
                Stem Lab Setup
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero